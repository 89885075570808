var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[(_vm.routeFrom && (_vm.routeFrom.name === 'Accounts'))?_c('div',{staticClass:"back-link",on:{"click":function($event){return _vm.$router.push({ name: 'Accounts' })}}},[_c('b-icon-chevron-left',{attrs:{"font-scale":"1.3"}}),_c('span',[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(_vm.$t('words.accountsManagement')))+" ")])],1):_c('div',{staticClass:"back-link",on:{"click":function($event){return _vm.$router.push({ name: 'Groups' })}}},[_c('b-icon-chevron-left',{attrs:{"font-scale":"1.3"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('addGroup.groupsList'))+" ")])],1),_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.loading,"variant":'white',"opacity":0.7,"blur":'2px',"rounded":"sm","no-wrap":""}}),_c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-row',[_c('b-col',{staticClass:"detail-forms",attrs:{"cols":"8"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('addGroup.title'))+" ")]),_c('form',[(_vm.isFieldVisible('name', _vm.formConfig.hiddenFields))?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('ValidationProvider',{attrs:{"rules":_vm.isFieldRequired('name', _vm.formConfig.requiredFields)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('label',{class:_vm.isFieldRequired('name', _vm.formConfig.requiredFields)},[_vm._v(" "+_vm._s(_vm.$t('addGroup.form.name.label'))+" ")]),_c('p',{staticClass:"label-help"},[_vm._v(" "+_vm._s(_vm.$t('addGroup.form.name.label'))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.$t('addGroup.form.name.label')},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]):_vm._e(),(_vm.isFieldVisible('description', _vm.formConfig.hiddenFields))?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('description', _vm.formConfig.requiredFields)},[_vm._v(" "+_vm._s(_vm.$t('addGroup.form.description'))+" ")]),_c('ValidationProvider',{attrs:{"rules":_vm.isFieldRequired('description', _vm.formConfig.requiredFields)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}}),_vm._v(" "),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]):_vm._e()])]),_c('b-col',{staticClass:"detail-card",attrs:{"cols":"4"}},[_c('UsergroupThumbnail',{attrs:{"display_name":_vm.name},on:{"set":_vm.setThumbnail}}),_c('div',{staticClass:"side-footer"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return handleSubmit(_vm.addGroup)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return handleSubmit(_vm.addGroupAndContinue)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.saveContinue'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }